import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import { Wrapper } from '../styled-components/header'

import PrivacyPolicyAndCookiesComponent from '../components/pages/PrivacyPolicyAndCookies';

const PrivacyPolicyAndCookies = () => {
  return (
    <Layout>
      <Helmet>
        <title>Stanford Sonoma - Privacy Policy and Cookies</title>
      </Helmet>
      <PrivacyPolicyAndCookiesComponent />
    </Layout>
  );
};

export default PrivacyPolicyAndCookies;
