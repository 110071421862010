import React from 'react';
import { Container, Navigation, Content, Grid } from './styles';

const PrivacyPolicyAndCookies = () => {
  return (
    <Container>
      <h1 className="terms-title">TERMS AND CONDITIONS</h1>
      <Grid>
        <Navigation>
          <h4 href="#intro">Jump to...</h4>
          <ul>
            <li>
              <a href="#intro">Intro</a>
            </li>
            <li>
              <a href="#modification-of-services">Modification of Services</a>
            </li>
            <li>
              <a href="#limitations-of-your-use-of-services">Limitations of Your Use of Services</a>
            </li>
            <li>
              <a href="#modification-of-the-terms-of-use">Modification of the Terms of Use</a>
            </li>
            <li>
              <a href="#termination">Termination</a>
            </li>
            <li>
              <a href="#childrens-privacy-and-site-age-limitations">
                Children’s Privacy and Site Age Limitations
              </a>
            </li>
            <li>
              <a href="#pricing-or-other-information-errors">Pricing or Other Information Errors</a>
            </li>
            <li>
              <a href="#payment-and-billing">Payment and Billing</a>
            </li>
            <li>
              <a href="#privacy">Privacy</a>
            </li>
            <li>
              <a href="#third-party-links">Third-Party Links</a>
            </li>
            <li>
              <a href="#your-use-of-the-online-services">Your Use of the Online Services </a>
            </li>
            <li>
              <a href="#stanford-sonomas-intellectual-property">
                Stanford Sonoma’s Intellectual Property
              </a>
            </li>
            <li>
              <a href="#content-sumbitted-by-you">Content Submitted by You</a>
            </li>
            <li>
              <a href="#employment-applications">Employment Applications</a>
            </li>
            <li>
              <a href="#no-agency-relationship">No Agency Relationship</a>
            </li>
            <li>
              <a href="#important-legal-terms">Important Legal Terms </a>
              <ul>
                <li>
                  <a href="#jurisdiction-and-governing-law">Jurisdiction and Governing Law </a>
                </li>
                <li>
                  <a href="#claims-limitation">Claims Limitation</a>
                </li>
                <li>
                  <a href="#disclaimer-of-warranties">Disclaimer of Warranties</a>
                </li>
                <li>
                  <a href="#indemnification">Indemnification</a>
                </li>
                <li>
                  <a href="#limitation-of-liability">Limitation of Liability</a>
                </li>
                <li>
                  <a href="#new-yersey-residents">New Jersey Residents</a>
                </li>
                <li>
                  <a href="#consent-to-communicate=electronically">
                    Consent to Communicate Electronically{' '}
                  </a>
                </li>
                <li>
                  <a href="#entire-agreement-and-severability">Entire Agreement and Severability</a>
                </li>
              </ul>
            </li>
            <li>
              <a href="#contact-us">Contact Us</a>
            </li>
          </ul>
        </Navigation>
        <Content>
          <a class="anchor" id="intro"></a>
          <h3>Intro</h3>
          <p>Last Updated: 01/01/2021</p>
          <p>Effective Date: 01/01/2021 PLEASE READ</p>
          <p>
            <b>
              THESE TERMS AND CONDITIONS OF USE CAREFULLY. BY ACCESSING OR USING THE WEBSITE, MOBILE
              APPLICATION OR ONLINE SERVICE PROGRAM WHERE THESE TERMS AND CONDITIONS OF USE ARE
              LINKED, OR OTHERWISE AGREEING TO THESE TERMS AND CONDITIONS OF USE, YOU UNDERSTAND AND
              AGREE TO BE BOUND BY THESE TERMS AND CONDITIONS OF USE AND RECOGNIZE THAT YOU MAY BE
              WAIVING CERTAIN RIGHTS.
            </b>
          </p>
          <p>
            These Terms and Conditions of Use (“Terms and Conditions of Use" or “Terms of Use”)
            apply to your access to, and use of, any Stanford Sonoma Corp. ("Stanford Sonoma")
            website and online service or program where these Terms and Conditions of Use are posted
            or linked ("Stanford Sonoma Online Services"). These Terms and Conditions of Use are a
            legal and binding agreement between you and Stanford Sonoma, and, as applicable, its
            subsidiaries, affiliates, and related entities ("Affiliates") (Stanford Sonoma and its
            Affiliates are referred to herein, as the context may require, as "we", "us", or "our").
            These Terms of Use govern your use of the Online Services, which includes the programs,
            offers, content, information, services, and features made available through the Online
            Services. If you do not agree to these Terms and Conditions of Use, then you should not
            use the Online Services. By continuing to use the Online Services, you agree that such
            use is legally sufficient consideration under these Terms of Use.
          </p>
          <p>
            <b>
              THESE TERMS AND CONDITIONS OF USE CONTAIN PROVISIONS THAT LIMIT OUR LIABILITY TO YOU.
              Your use of the Online Services is at your risk. If you are dissatisfied with the
              OnlineServices in any way, its programs, offers, contents, features, or these Terms
              and Conditions of Use, your sole and exclusive remedy is to discontinue accessing and
              using the Online Services.
            </b>
          </p>
          <a class="anchor" id="modification-of-services"></a>
          <h3>Modification of Services</h3>
          <p>
            Stanford Sonoma reserves the right, in its sole discretion,to modify the services from
            time to time and without notice, including by removing, adding, ormodifying products
            and/or third-party vendors and/or manufacturers from the product catalog.Stanford Sonoma
            shall have no liability to you for any modification or discontinuation of theservices.
            If you object to any such changes, your sole recourse shall be to cease using
            theservices.Limitations on Your Use of ServicesSubject to these Terms and Conditions of
            Use,Stanford Sonoma grants you a limited, revocable right to Access and use the site
            solely foryour own use. You may not attempt, or authorize, encourage, or support others'
            attempts, tocircumvent, reverse engineer, decrypt, break or otherwise alter or interfere
            with the OnlineServices. You acknowledge and agree that you may not copy, distribute,
            sell, resell, orexploit for any commercial purposes any portion of the services, or any
            products accessiblethrough the services. Stanford Sonoma reserves the right to suspend
            or terminate use of thiswebsite or its services by you or other users in its sole
            discretion.
          </p>
          <a class="anchor" id="limitations-of-your-use-of-services"></a>
          <h3>Limitations on Your Use of Services</h3>
          <p>
            Subject to these Terms and Conditions of Use, Stanford Sonoma grants you a limited,
            revocable right to Access and use the site solely for your own use. You may not attempt,
            or authorize, encourage, or support others' attempts, to circumvent, reverse engineer,
            decrypt, break or otherwise alter or interfere with the Online Services. You acknowledge
            and agree that you may not copy, distribute, sell, resell, or exploit for any commercial
            purposes any portion of the services, or any products accessible through the services.
            Stanford Sonoma reserves the right to suspend or terminate use of this website or its
            services by you or other users in its sole discretion.
          </p>
          <a class="anchor" id="modification-of-the-terms-of-use"></a>
          <h3>Modification of the Terms of Use</h3>
          <p>
            Stanford Sonoma may amend, supplement, or modify these Terms of Use from time to time
            byposting the amended Terms of Use on the site. By continuing to use the site after the
            amendedTerms of Use have been posted you agree to be bound by any such amendment or
            modification. Ifyou object to any such changes, your sole recourse shall be to cease
            using theservices.
          </p>
          <a class="anchor" id="termination"></a>
          <h3>Termination</h3>
          <p>
            If you materially breach the terms of this agreement, Stanford Sonoma maysuspend your
            ability to use the services or may terminate this agreement effective immediatelyand
            without notice to you. You may terminate this agreement at any time by providing
            writtennotice to us using the information contained in the{' '}
            <a href="#contact-us">Contact Us</a>section below. Please note thatif you exercise your
            right to terminate this agreement, we will be unable to continue toprovide you with
            services or access to the Online Services.{' '}
          </p>
          <a class="anchor" id="childrens-privacy-and-site-age-limitations"></a>
          <h3>Children’s Privacy and Site Age Limitations</h3>
          <p>
            This website is intended for use by persons aged 13 or older, and by your use ofthis
            site you affirm that you are at least 13 years of age. In addition, if you are under
            theage of 18, you are only permitted to use this website with the consent of your
            parents or anadult guardian. Stanford Sonoma will not knowingly allow registration of an
            account for anyperson that Stanford Sonoma believes to be younger than 13. We also do
            not knowingly collectany personally identifiable information from any persons under the
            age of 13, and, if wediscover that we have been provided any such information, we will
            delete this information fromour records.
          </p>
          <a class="anchor" id="pricing-or-other-information-errors"></a>
          <h3>Pricing or Other Information Errors</h3>
          <p>
            Although Stanford Sonoma strives at all timesto maintain the accuracy of information
            maintained on the Online Services, including pricinginformation and product details,
            occasionally pricing or other information errors may occur onthis site. In the event
            that any Stanford Sonoma product that is available through this siteis mistakenly listed
            at an incorrect price or with other incorrect information, StanfordSonoma reserves the
            right either to refuse or cancel the order.
          </p>
          <a class="anchor" id="payment-and-billing"></a>
          <h3>Payment and Billing</h3>
          <p>
            You acknowledge and agree that for each order you place through the Online Services, the
            full cost of products you order (for which prices are subject to change without notice)
            will be assessed against your payment card. You acknowledge and agree that a SMS or web
            browser command originating from your account constitutes an authorization for Stanford
            Sonoma to charge this amount and you assume all liability for and shall promptly pay any
            and all charges. Without limiting the foregoing, you acknowledge and agree that you will
            pay assessed charges for any and all orders placed from your account, regardless of
            whether you actually receive the products. Stanford Sonoma will provide you with
            reasonable notice of any material modification in the fees charged for the services,
            which notice may be provided by posting the new fee schedule on the site.
          </p>
          <a class="anchor" id="privacy"></a>
          <h3>Privacy</h3>
          <p>
            Stanford Sonoma has an unrestricted right to collect and use any personal information
            you provide in connection with the services, provided that any such use shall be in
            accordance with our <a href="#privacy">Privacy Policy</a> available publicly on the
            website.
          </p>
          <a class="anchor" id="third-party-links"></a>
          <h3>Third-Party Links</h3>
          <p>
            In an attempt to provide increased value to our customers and other site visitors,
            Stanford Sonoma may provide links to sites operated by third parties, including social
            media sites. However, even if the third party is affiliated with Stanford Sonoma,
            Stanford Sonoma has no control over these linked sites, all of which have separate terms
            and privacy practices, independent of Stanford Sonoma. These linked sites are only for
            your convenience, you acknowledge that you access them at your own risk.{' '}
          </p>
          <a class="anchor" id="your-use-of-the-online-services"></a>
          <h3>Your Use of the Online Services</h3>
          <p>
            While using the Online Services, you are required to comply with all applicable
            statutes, orders, regulations, rules and other laws. You may not use the Online Services
            for any fraudulent or unlawful purpose, and you may not take any action to interfere
            with the Online Services or any other party’s use of the Online Services. You are
            granted a limited, non-exclusive right to create text hyperlinks to the Online Services
            for informational purposes, provided such links do not portray us in a false,
            misleading, derogatory or otherwise defamatory manner and provided that the linking site
            does not contain any material that is unlawful, harmful, harassing, defamatory,
            threatening, intimidating, fraudulent, tortious, vulgar, obscene, hateful, pornographic,
            spam, discriminatory, violative of privacy or publicity rights, infringing of
            intellectual property or other proprietary rights, or otherwise objectionable in our
            sole discretion, including unauthorized or unsolicited advertising.{' '}
          </p>
          <a class="anchor" id="stanford-sonomas-intellectual-property"></a>
          <h3>Stanford Sonoma’s Intellectual Property</h3>
          <p>
            The Online Services contain copyrighted and/or proprietary subject matter that is owned
            by Stanford Sonoma and/or its licensors. All such material is protected by relevant
            intellectual property laws, including copyright, trademark, patent and/or trade secret
            laws. Such material may not be modified, reproduced, transmitted, sold, offered for
            sale, publicly displayed, or redistributed in any way without our prior written
            permission and the prior written permission of any other applicable rights licensor. All
            trademarks, slogans and other indicia of origin ("Marks") that appear on or in
            connection with the services are the property of Stanford Sonoma and/or its affiliates,
            licensors and/or licensees. You are not authorized to use any such Marks.{' '}
          </p>
          <a class="anchor" id="content-sumbitted-by-you"></a>
          <h3>Content Submitted by You</h3>
          <p>
            You are responsible for any information, text, reviews, posts, images, videos or other
            materials or content that you post, upload to us, or transmit through the Online
            Services (“User Content”). PLEASE DO NOT POST OR SEND US ANY USER CONTENT, IDEAS,
            SUGGESTIONS, OR OTHER USER CONTENT THAT YOU WISH TO KEEP PRIVATE OR PROPRIETARY OR FOR
            WHICH YOU EXPECT TO RECEIVE COMPENSATION. By sending any ideas, concepts, know-how,
            proposals, techniques, suggestions or other User Content to us, you agree that: (i) we
            are free to use such User Content for any purpose, (ii) such User Content will be deemed
            not to be confidential or proprietary (iii) we may have something similar already under
            consideration or in development, and (iv) you are not entitled to any compensation or
            reimbursement of any kind from us under any circumstances unless otherwise expressly
            agreed in writing by us. Be aware that we have no obligation to keep User Content
            confidential unless explicitly stated. By submitting User Content to us directly or
            indirectly (including through any use of third party social media platforms directed at
            us), you grant to us (or warrant that the owner of such information and material has
            expressly granted to us) a royalty-free, perpetual, sublicensable, irrevocable, and
            unrestricted right and license to use, reproduce, display, modify, adapt, publish,
            perform, translate, transmit and distribute or otherwise make available to others such
            User Content (in whole or in part and for any purpose).{' '}
          </p>
          <a class="anchor" id="employment-applications"></a>
          <h3>Employment Applications</h3>
          <p>
            Some websites and mobile applications maintained by Stanford Sonoma allow you to submit
            applications for employment with us. Should we employ you, none of the materials
            provided on a Site constitute or should be considered part or of an employment contract
            or an offer for employment.{' '}
          </p>
          <a class="anchor" id="no-agency-relationship"></a>
          <h3>No Agency Relationship</h3>
          <p>
            Neither these Terms and Conditions of Use, nor any materials, programs, offers,
            features, or services of the Online Services, create any partnership, joint venture,
            employment, or other agency relationship between Stanford Sonoma, its Affiliates, and
            you. You may not enter into any contract on our behalf or bind us in any way.
          </p>
          <a class="anchor" id="important-legal-terms"></a>
          <h3>Important Legal Terms </h3>
          <a class="anchor" id="jurisdiction-and-governing-law"></a>
          <h4>Jurisdiction and Governing Law</h4>
          <p>
            Any legal action, suit, or proceeding arising out of or relating to these Terms of Use,
            or your use of the Online Services, shall be instituted exclusively in the federal or
            state courts located in Dallas, Texas. These Terms of Use shall be governed by Texas
            law, without regard to conflict of law principles.{' '}
          </p>
          <a class="anchor" id="claims-limitation"></a>
          <h4>Claims Limitation</h4>
          <p>
            <b>
              YOU AGREE THAT REGARDLESS OF ANY STATUTE OR LAW TO THE CONTRARY, ANY CLAIM OR CAUSE OF
              ACTION ARISING OUT OF OR RELATED TO USE OF THE STANFORD SONOMA ONLINE SERVICES OR
              THESE TERMS AND CONDITIONS OF USE MUST BE FILED WITHIN ONE YEAR AFTER SUCH CLAIM OR
              CAUSE OF ACTION AROSE OR BE FOREVER BARRED.
            </b>
          </p>
          <a class="anchor" id="disclaimer-of-warranties"></a>
          <h4>Disclaimer of Warranties</h4>
          <p>
            YOU EXPRESSLY AGREE THAT USE OF THE SERVICES IS AT YOUR OWN RISK. THE SERVICES ARE
            PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. STANFORD SONOMA EXPRESSLY DISCLAIMS ALL
            WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING, BUT NOT
            LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR USE OR
            PURPOSE, AND NON- INFRINGEMENT. STANFORD SONOMA MAKES NO WARRANTY THAT THE SERVICES WILL
            MEET YOUR REQUIREMENTS, OR THAT THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR
            ERROR FREE. YOU UNDERSTAND AND AGREE THAT ANY PRODUCTS YOU OBTAIN THROUGH USE OF THE
            ONLINE SERVICES IS DONE AT YOUR OWN RISK, AND STANFORD SONOMA MAKES NO WARRANTY
            REGARDING ANY DEALINGS WITH OR TRANSACTIONS ENTERED INTO WITH ANY OTHER PARTIES THROUGH
            THE SERVICES. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM
            STANFORD SONOMA OR THROUGH THE SERVICES SHALL CREATE ANY WARRANTY NOT EXPRESSLY MADE
            HEREIN.
          </p>
          <a class="anchor" id="indemnification"></a>
          <h4>Indemnification</h4>
          <p>
            You agree to indemnify, defend and hold harmless Stanford Sonoma, its agents, employees,
            representatives, licensors, affiliates, officers and directors, from and against any and
            all claims, liabilities, damages, losses, costs, expenses, fees (including reasonable
            attorneys' fees and court costs) resulting or arising from any third-party claim in
            connection with (a) any information you (or anyone accessing the services using your
            password) submit or transmit through the services, (b) your use of or access to the
            services, (c) your violation of these Terms of Use, (d) your violation of any rights of
            any third party, or (e) any viruses, trojan horses, worms, time bombs, cancelbots,
            spyware, or other similar harmful or deleterious programming routines input by you into
            the services.
          </p>
          <a class="anchor" id="limitation-of-liability"></a>
          <h4>Limitation of Liability</h4>
          <p>
            <b>
              WE AND OUR AFFILIATES, SUBSIDIARIES, DIVISIONS AND RELATED COMPANIES AS WELL AS OUR
              AGENTS, SUPPLIERS, SERVICE PROVIDERS AND RETAILERS (COLLECTIVELY, THE “RELEASEES”)
              WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING OUT OF OR RELATING TO THESE
              TERMS OF USE OR THE USE OR THE INABILITY TO USE THE ONLINE SERVICES CONTENT OR
              EXTERNAL LINKS, INCLUDING BUT NOT LIMITED TO DAMAGES CAUSED BY OR RELATED TO ERRORS,
              OMISSIONS, INTERRUPTIONS, DEFECTS, DELAY IN OPERATION OR TRANSMISSION, OR ANY COMPUTER
              VIRUS OR FAILURE.
            </b>
          </p>
          <p>
            <b>
              RELEASEES WILL ALSO NOT BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, EXEMPLARY,
              PUNITIVE OR CONSEQUENTIAL DAMAGES OR ANY LOSS OF DATA OR PROFITS, EVEN IF WE HAVE BEEN
              ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. RELEASEES ALSO SHALL NOT HAVE ANY
              LIABILITY OR RESPONSIBILITY FOR ANY ACTS, OMISSIONS OR CONDUCT OF ANY USER OR OTHER
              THIRD PARTY.
            </b>
          </p>
          <p>
            <b>
              SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES, SO THE
              ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
            </b>
          </p>
          <p>
            <b>
              REGARDLESS OF THE PREVIOUS SENTENCES, IF WE ARE FOUND TO BE LIABLE, OUR LIABILITY TO
              YOU OR TO ANY THIRD PARTY IS LIMITED TO THE LOWEST LIABILITY LIMITATION ALLOWED BY
              APPLICABLE LAW.
            </b>
          </p>
          <a class="anchor" id="new-yersey-residents"></a>
          <h4>New Jersey Residents</h4>
          <p>
            If you are a consumer residing in New Jersey, the following provisions of these Terms of
            Use do not apply to you (and do not limit any rights that you may have) to the extent
            that they are unenforceable under New Jersey law: (a) Disclaimer of Warranties; (b)
            Limitation of Liability; and (c) Indemnification. According to N.J.S.A. 56:12-16, you
            may have additional rights if you are a New Jersey resident and other provisions of
            these Terms of Use are found to violate an established legal right.
          </p>
          <a class="anchor" id="consent-to-communicate=electronically"></a>
          <h4>Consent to Communicate Electronically</h4>
          <p>
            When you use the Online Services or send communications to us via email, you are
            communicating with us electronically. You consent to receive electronically any
            communications related to your use of the Online Services. We may communicate with you
            by email or by posting notices through the Online Services. You agree that all
            agreements, notices, disclosures and other communications that are provided to you
            electronically satisfy any legal requirement that such communications be in writing. All
            notices from us intended for receipt by you shall be deemed delivered and effective when
            sent to the email address you provide to us.
          </p>
          <a class="anchor" id="entire-agreement-and-severability"></a>
          <h4>Entire Agreement and Severability</h4>
          <p>
            These Terms and Conditions of Use, together with any other applicable terms included
            within the Online Services, and/or the materials, programs, offers, features, and
            services made available through the Online Services, as each is currently posted on the
            Online Services, constitute the entire agreement between us and you with respect to your
            use of the Online Services and the materials, and supersede all previous written or oral
            agreements between us and you with respect to such subject matter. If, for any reason, a
            court of competent jurisdiction finally determines any provision of these Terms and
            Conditions of Use or any portion thereof to be unenforceable, such provision shall be
            enforced to the maximum extent permissible so as to give the intended effect thereof,
            and the remainder of these Terms and Conditions of Use shall continue in full force and
            effect.
          </p>
          <a class="anchor" id="contact-us"></a>
          <h3>Contact Us</h3>
          <p>
            If you have questions about these Terms of Use, please contact us through this online
            form or by writing us at info@stanfordsonoma.com.{' '}
          </p>
          <p>
            Additionally, under California Civil Code Section 1789.3, California users are entitled
            to the following consumer rights notice: California residents may reach the Complaint
            Assistance Unit of the Division of Consumer Services of the California Department of
            Consumer Affairs by mail at 1625 North Market Blvd., Sacramento, CA 95834, or by
            telephone at (916) 445-1254 or (800) 952-5210
          </p>
        </Content>
      </Grid>
    </Container>
  );
};

export default PrivacyPolicyAndCookies;
