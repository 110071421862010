import styled from 'styled-components';

export const Container = styled.div`
    max-width: 1400px;
    margin: 2rem auto;
    color: rgba(0,0,0,0.8);

    h1 {
        color: rgb(52, 101, 127);
        text-align: center;
    }
    a {
        color: rgba(0,0,0,0.8);
    }
  .terms-title {
    margin-top: 10rem;
  }
`;

export const Navigation = styled.div`
    margin: 0 1rem;
    min-width: 300px;
    ul {
        padding-left: 1rem;
    }
    li {
        padding: 0.5rem 0;
    }
`;

export const Content = styled.div`
    margin: 0 1rem;

    a.anchor {
        display: block;
        position: relative;
        top: -100px;
        visibility: hidden;
    }
`;

export const Grid = styled.div`
    display: flex;
    justify-content: flex-start;

    @media all and (max-width: 600px) {
        display: block;
    }
`;
